import * as React from "react"
import { Link } from "gatsby"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <main>
        <title>Page not found</title>
        <h1>Page not found</h1>
        <p>Sorry we couldn't find what you were looking for.</p>
        <Link to="/">Go back home</Link>
      </main>
    )
  )
}

export default NotFoundPage
